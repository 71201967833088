import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom'
import './login.css'
import { Form, Input, Button, message } from 'antd'
import { loginToHome } from '../../route/Index'
import { baseURL } from '../../fetch/axios';
import { RedoOutlined } from '@ant-design/icons';
import blankPic from '../../images/icon_jiazaishibai.png'
import { login, getPublicAccessPrefix, getSubcompanyLogo, weChatLogin, getAppId } from '../../fetch/api';

interface initData {
	ulogin: string,
	upsw: string,
	imageCode: string,
	imageSrc: string
	uuid: string,
	curTabIndex: number,
	qrCodePicUrl: any,
	loginAppid: any
}

let initialState: initData = {//数据初始化
	imageSrc: "",
	ulogin: "",
	upsw: "",
	imageCode: "",
	uuid: "",
	curTabIndex: 0,
	qrCodePicUrl: null,
	loginAppid: null
}
const assignReducer = (state: initData, action: any) => {
	if (action === 'RESET') {
		return JSON.parse(JSON.stringify(initialState));
	}
	return Object.assign({}, state, action);
};
const Login: React.FC<any> = () => {
	useEffect(() => {
		//如果是已授权返回的就直接保存授权信息，否则就请求接口得到appid以便可以执行授权操作
		reLoadImage()
		let authcode = getQueryVariable('auth_code')
		// let res = getQueryVariable('res')
		// if (res) {
		// 	loginSuccessEvent(JSON.parse(decodeURI(res)))
		// } 

		if (authcode) {
			weChatLoginEvent(authcode)
		} else {
			getAppIdEvent()
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const [FormRef] = Form.useForm()
	const [state, setState] = useReducer(assignReducer, JSON.parse(JSON.stringify(initialState)));
	const [loadingMain, setLoadingMain] = useState<boolean>(false)
	const history = useHistory()
	
	
	const getQueryVariable = (variable: any) => {//获取地址栏参数
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) { return pair[1]; }
		}
		return (false);
	}
	const getNum = () => {
		let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
		let nums = "";
		for (let i = 0; i < 32; i++) {
			let id = parseInt(String(Math.random() * 61));
			nums += chars[id];
		}
		return nums;
	}
	const reLoadImage = () => {
		let id = getNum()
		setState({
			imageSrc: baseURL + "/imageService/base/getImageCode?uuid=" + id,
			uuid: id
		})
	}
	const loginSuccessEvent = (res: any) => {
		// 登录成功感后执行的事件
		if (res.data) localStorage.setItem('userInfo', JSON.stringify(res.data))
		if(res.data?.token) localStorage.Authorization = res.data.token
		setTimeout(() => {
			// history.push(loginToHome)
			history.push({
				pathname: loginToHome,
				state: {
					updatePasswordFlag: res.data?.updatePasswordFlag ? res.data?.updatePasswordFlag : false,
				}
			})
		}, 500);
	}
	const weChatLoginEvent = (authcode: any) => {
		setLoadingMain(true)
		let params = {
			code:authcode
		}
		weChatLogin(params).then((res: any) => {
			setLoadingMain(false)
			if (res.code === 200) {
				loginSuccessEvent(res)
			} else {
				message.error(res.message)
				setTimeout(() => {
					window.location.href = sessionStorage.curLocationHref
				},1000)
			}
		}).catch(() => { 
			setLoadingMain(false) 
			setTimeout(() => {
				window.location.href = sessionStorage.curLocationHref
			},1000)
		})
	}
	const loginEvent = () => {
		FormRef.validateFields().then((values: any) => {
			if (!values.errorFields) {
				setLoadingMain(true);
				let params = {
					loginName:values.ulogin,
					password:values.upsw,
					imageCode:values.imageCode,
					uuid:state.uuid
				}
				
				login(params).then((res: any) => {
					if (res.code === 200) {
						loginSuccessEvent(res)
					} else {
						reLoadImage()
						message.error(res.message)
					}
					setLoadingMain(false)
				}).catch(() => {
					reLoadImage()
					setLoadingMain(false)
				})
			}
		})

	}
	//企业微信授权登录
	const getAppIdEvent = () => {
		//获取企业微信appid
		setLoadingMain(true)
		getAppId({}).then((res: any) => {
			setLoadingMain(false)
			if (res.code === 200) {
				setState({
					loginAppid: res.data?.appId ? res.data.appId : null
				})
			}
		}).catch(() => { setLoadingMain(false) })
	}
	const GetRandomNum = (Min: number, Max: number) => {//生成10~128随机位数的a-zA-z0-9
		var Range = Max - Min;
		var Rand = Math.random();
		return (Min + Math.round(Rand * Range));
	}
	const qiyeLogin = () => {
		if (!state.loginAppid) {
			message.error('登录异常！')
			return
		}
		let redirect_uri = encodeURI(window.location.href)
		sessionStorage.setItem('curLocationHref',redirect_uri)
		let statestr = ''
		let strs = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
		var randomNums = GetRandomNum(10, 127);
		for (let index = 0; index < randomNums; index++) {
			statestr += strs[Math.floor(Math.random() * strs.length)]
		}
		let result = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=${state.loginAppid}&redirect_uri=${redirect_uri}&state=${statestr}&usertype=member`
		// window.open(result)
		window.location.href = result
	}
	return (
		<div className="loginContainer">
			<div className="loginBg"><img src={require('../../images/loginBg.png')} alt="" /></div>
			<div className="loginCenter">
				<div className="loginLogo"></div>
				<div className="login_tab">
					{
						['密码登录', '扫码登录'].map((tabText: any, index: number) => (
							<span
								onClick={() => {
									if (index === 0) {
										setState({
											curTabIndex: index
										})
									} else {
										qiyeLogin()
									}
								}}
								className={`${state.curTabIndex === index ? 'actived' : ''}`}
								key={`loginTab_${Math.random()}`}>{tabText}</span>
						))
					}
				</div>
				{state.curTabIndex === 0 &&
					<div className="form_box">
						<Form name="basic" layout="inline" form={FormRef} initialValues={{ remember: true }} >
							<div className="login_enter_box">
								<div className="login_enter_icon iconfont iconicon_yong_hu"></div>
								<Form.Item label="" name="ulogin" rules={[
									{ required: true, message: '请输入用户名' }
								]}>
									<Input 
										autoComplete="off" 
										placeholder="请输入用户名" 
										className="login_enter_box_input names"
										onPressEnter={() => loginEvent()} />
								</Form.Item>
							</div>
							<div className="login_enter_box">
								<div className="login_enter_icon iconfont iconicon_mi_ma"></div>
								<Form.Item label="" name="upsw" rules={[
									{ required: true, message: '请输入密码' }
								]}>
									<Input.Password 
										autoComplete="off" 
										placeholder="请输入密码" 
										className="login_enter_box_input"
										onPressEnter={() => loginEvent()} />
								</Form.Item>
							</div>
							<div className="login_enter_box">
								<div className="login_enter_icon iconfont iconicon_yan_zheng"></div>
								<Form.Item label="" name="imageCode" rules={[
									{ required: true, message: '请输入验证码' }
								]}>
									<Input 
										autoComplete="off" 
										placeholder="请输入验证码" 
										className="login_enter_box_inputyzm"
										onPressEnter={() => loginEvent()} />

								</Form.Item>
								<div className="login_yzmpic">
									<img src={state.imageSrc ? state.imageSrc : blankPic} />
									<div className="login_yzmbtn" onClick={() => reLoadImage()}></div>
								</div>
							</div>
						</Form>
						<div className="login_submit_btn" onClick={() => loginEvent()} onKeyDown={() => loginEvent()}>登录</div>
					</div>
				}
			</div>
			<div className="footer">
				<span>备案号：</span>
				<a href="https://beian.miit.gov.cn" target="_blank" style={{color: '#333'}}>蜀ICP备2021011009号-1</a>
				<a href="https://beian.miit.gov.cn" target="_blank" className="ml20" style={{color: '#333'}}>蜀ICP备2021011009号-2</a>
				{/* <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402001248" target="_blank" className="ml20" style={{color: '#333'}}>川公网安备51010402001248号</a> */}
			</div>
		</div>
	)
}

export default Login